"use client"
import { storeAssigned } from "@/services/Methods/normalMethods"
import { useRouter } from "next/navigation"

export default function Custom404() {
    const router = useRouter();
    return (
        <>
            <div className="wrapper_error">
                <div className="wrapper_animation">
                    <span className="first">4</span>
                    <span className="second_div">
                        <div className="eyes">
                            <div className="eyes_inner"></div>
                        </div>
                        <div className="lips"></div>
                        <div className="eyes right">
                            <div className="eyes_inner right"></div>
                        </div>
                    </span>
                    <span className="last">4</span>
                </div>
                <div className="eror_inro">
                    <h4>Oops!</h4>
                    <p>Well, this is awkward, the page you were trying to view does not exist.</p>
                    <button onClick={() => router.push(`/${storeAssigned()}`)}>
                        Go Home
                    </button>
                </div>
            </div>

        </>
    )
}